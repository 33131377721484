import React, { useState, useEffect } from 'react'
import SabpaisaPaymentGateway from './SabpaisaPaymentGateway';
import { useParams } from 'react-router-dom';
import axios from "axios";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
  
const Addcasecopy = (props) => {
  const { rechargeAmt, payerName, payerEmail, payerMobile } = useParams();
  const[qrCodeResponse, setQrCodeResponse] = useState(null);
  const [caseId, setCaseId] = useState(null);
  const [isbn, setIsbn] = useState(null);

  useEffect(() => {
    const urlPath = window.location.pathname; 
    const pathSegments = urlPath.split('/');
    console.log(pathSegments);

    if (pathSegments.length >= 5) {
      setCaseId(pathSegments[2]); 
      setIsbn(pathSegments[5]); 
    }
  }, []);


  const handleButtonClick = () => {
    const amount = caseId; 
    const mobile = isbn; 
    const redirectUrl = `/Manual_Payment?&amount=${amount}&mobile=${mobile}`;
    window.location.href = redirectUrl;
  };

  // const handleButtonClick1 = () => {
  //   const amount = caseId; 
  //   const mobile = isbn; 
  //   const redirectUrl = `https://payment.rkbattle.com/index.php?amount=${amount}&mobile=${mobile}`;
  //   // const redirectUrl = `/Manual_Payment?&amount=${amount}&mobile=${mobile}`;
  //   window.location.href = redirectUrl;
  // };

  return (
    <div className="container-fluid" style={{ marginTop: "100px" }}>
  {/* <div>
    <p>Case ID: {caseId}</p>
    <p>ISBN: {isbn}</p>
  </div> */}

      {/* <form className='xyz' onSubmit={handleSubmit}>
        <div className="add-fund-box mt-3"style={{ paddingTop: "0px", height: "60px" }}>
    <div className="d-flex align-items-center" style={{ backgroundColor: "#fafafa", border: "1px solid #e0e0e0",borderRadius: "7px",}}>
                <div className="d-flex align-items-center"
                  style={{
                    height: "60px",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                <img
                  width="40px"
                  src="/UPI.png"
                  alt=""
                  style={{
                    marginLeft: "7px",
                    paddingBottom: "10px",
                    paddingLeft: "3px",
                    paddingTop: "5px",
                  }}
                />
                </div>

        <div className="d-flex justify-content-center flex-column ml-4">
              <div id='renderSabPaisa' className='jss30'></div>
                      <div className="jss30">
                        <button style={{borderRadius:"8px", border:"none",backgroundColor:"none", fontWeight:"bold", fontSize:"20px"}}
                          type="submit"  value="Submit" >Proceed To Pay</button>
                      </div>
                      <div className="jss31"></div>
              </div>
        </div>

</div>

<div
  className="d-flex align-items-center"
  style={{
    backgroundColor: "#fafafa",
    border: "1px solid #e0e0e0",
    borderRadius: "7px",
  }}
>
  <div
    className="d-flex align-items-center"
    style={{
      height: "60px",
      display: "flex",
      textAlign: "center",
    }}
  >
    <img
      width="40px"
      src="/UPI.png"
      alt=""
      style={{
        marginLeft: "7px",
        paddingBottom: "10px",
        paddingLeft: "3px",
        paddingTop: "5px",
      }}
    />
  </div>


  

  <div className="d-flex justify-content-center flex-column ml-4">
    <div id='renderkavpayin' className='jss30'></div>
    <div className="jss30">
      <a href={qrCodeResponse} style={{ color: "#000000" }} target="_blank" rel="noopener noreferrer">Pay Now With Phonepe Paytm</a>

    </div>
    <div className="jss31"></div>
  </div>
</div>


  <SabpaisaPaymentGateway env={"prod"} clientCode={clientCode} transUserName={transUserName} transUserPassword={transUserPassword} authkey={authkey} authiv={authiv} payerName={"VT LUDO CLUB"} payerEmail={"info@vtludoclub.com"} payerMobile={payerMobilee} clientTxnId={clientTxnId} amount={amount} payerAddress={payerAddress}  isOpen={isOpen} />
      </form> */}
      
      {/* <button onClick={handleButtonClick}>UPI Payment</button> */}
      <>
      
      <div className="d-flex align-items-center" style={{ backgroundColor: "#fafafa", border: "1px solid #e0e0e0",borderRadius: "7px",}}>
                <div className="d-flex align-items-center"
                  style={{ height: "60px", display: "flex", textAlign: "center", }}
                >
                <img width="40px" src="/UPI.png"
                  alt="" style={{ marginLeft: "7px", paddingBottom: "10px", paddingLeft: "3px", paddingTop: "5px",}}
                />
                </div>

              <div className="d-flex justify-content-center flex-column ml-4">
                     <div id='renderSabPaisa' className='jss30'></div>
                      <div className="jss30">
                        <button style={{borderRadius:"8px", border:"none",backgroundColor:"none", fontWeight:"bold", fontSize:"20px"}}
                          onClick={handleButtonClick}>Proceed To Pay</button>
                      </div>
                      <div className="jss31"></div>
              </div>
      </div>
    
      {/* <div className="d-flex align-items-center mt-3" style={{ backgroundColor: "#fafafa", border: "1px solid #e0e0e0",borderRadius: "7px",}}>
                <div className="d-flex align-items-center" style={{ height: "60px", display: "flex", textAlign: "center", }} >
                <img width="40px" src="/qr-scan.svg"  alt=""
                  style={{ marginLeft: "7px", paddingBottom: "10px", paddingLeft: "3px", paddingTop: "5px", }}
                />
                </div>

                <div className="d-flex justify-content-center flex-column ml-4">
                            <div id='renderSabPaisa' className='jss30'></div>
                              <div className="jss30">
                                <button style={{borderRadius:"8px", border:"none",backgroundColor:"none", fontWeight:"bold", fontSize:"20px"}}
                                  onClick={handleButtonClick1}>Proceed To QR Pay</button>
                              </div>
                              <div className="jss31"></div>
                </div>
      </div> */}
     
      </>

      
         <div style={{ margin:"20px 20px" }}>
      <span style={{ color: "red", fontSize:"14px"}}>
      जब आप पेमेंट ऐड कर रहे हो तो वहाँ जब तक ऑटोमैटिक पेमेंट  
      <br/>
      ऐड नहीं हो जाये तब तक ना बैक आये और ना ही refresh करे ।
                </span></div>
                <div style={{ margin:"20px 20px" }}>
      <span style={{ color: "red", fontSize:"14px"}}>
         
                </span></div>

    </div>
  )
}

export default Addcasecopy